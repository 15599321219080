import React from 'react';

const Logo = () => {

    return (
        <>site404</>
    );
};

export default Logo;
